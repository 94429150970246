.card[data-v-b861823e] {
  width: 98%;
  margin: 10px auto;
  font-size: 16px;
}
.location[data-v-b861823e] {
  float: right;
  margin-bottom: 6px;
}
.location span[data-v-b861823e] {
  color: #409eff;
}
.btn[data-v-b861823e] {
  float: left;
  margin-bottom: 6px;
}
.paging[data-v-b861823e] {
  float: right;
  margin-top: 10px;
  margin-bottom: 10px;
}
